import {AutoHandle} from 'utils/customHandle';

/**用于获取定制化插槽组件逻辑获取 */
let speical = {
};
/*eslint-disable no-undef */
try {
    let {speicalMap,useList} = require(`../../custom/${SPECIAL_ENV}/customSlot/useList.js`);
    let {
        views
    } = useList;
    speical = views.includes('CameraConfig') ? speicalMap.get('CameraConfig') : {};
} catch (e) {
    speical = {};
}

/**
 * 该页面所有对外提供插槽位置组件名称集合
 */
let Slot = {
    CameraConfigDemo1:'',
    CameraConfigDemo2:''
};

/**替换 slot */
export function replaceSlot (customSlot) {
    if(speical.replace) {
        return speical.replace(customSlot);
    }
    return Slot;
}

/**
 *
 * @param {object} me
 * 指向当前实例
 * 用来收集所有插槽，传入 handleCustom进行定制化处理
 */
export function _Custom (_this) {
    let options = {};
    return AutoHandle(Slot,_this,options,speical);
}

